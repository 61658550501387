import { handleError, handleSuccess } from '../handlers';
import {
    MoveInspirationsMutationVariables,
    useMoveInspirationsMutation,
} from '../reactQueryHooks';
import { useQueryClient } from '@tanstack/react-query';

import useMutationHook from 'apis/useMutationHook';

const useMoveInspirations = () => {
    const queryClient = useQueryClient();

    const { mutationHandler, isLoading } =
        useMutationHook<MoveInspirationsMutationVariables>(
            useMoveInspirationsMutation,
            handleSuccess(queryClient),
            handleError
        );

    return {
        isLoading,
        moveInspirations: mutationHandler,
    };
};

export default useMoveInspirations;
