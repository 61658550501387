import defaults from './defaults.json';
import localhost from './localhost.json';
import prCte from './pr-cte.json';
import prPpe from './pr-ppe.json';

const defaultsByEnv: Record<string, Object> = {
    'pr-ppe': prPpe,
    'pr-cte': prCte,
    localhost,
};

const usen =
    process.env.NEXT_PUBLIC_IKEA_ENV! in defaultsByEnv
        ? {
              ...defaults,
              ...defaultsByEnv[process.env.NEXT_PUBLIC_IKEA_ENV!],
          }
        : defaults;

export default usen;
