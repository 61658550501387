export const getCookie = (cookieName: string) => {
    if (typeof document === 'undefined') {
        return '';
    }
    const name = cookieName + '=';
    const cookies = document?.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return '';
};

export const getDomain = () => {
    if (window.location.hostname === 'localhost') {
        return 'localhost';
    }
    if (window.location.hostname.endsWith('ikea.com')) {
        return '.ikea.com';
    }
    if (window.location.hostname.endsWith('ikeadt.com')) {
        return '.ikeadt.com';
    }
    // DEV MODE! Prevent this
    return window.location.hostname;
};

export const setCookie = (
    cookieName: string,
    country: string,
    language: string,
    value: string,
    exdays = 365,
    setPath = false
) => {
    const expirationDate = new Date();
    expirationDate.setTime(
        expirationDate.getTime() + exdays * 24 * 60 * 60 * 1000
    );
    const expires = 'expires=' + expirationDate.toUTCString();
    document.cookie = `${cookieName}=${value};${expires};domain=${getDomain()};path=/${
        setPath ? `${country}/${language}/` : ''
    }`;
};
