import { useMemo } from 'react';

import useUserPreferences from 'apis/user-preferences/useUserPreferences';

import useLocale from 'hooks/useLocale';

export type CommonVariables = {
    languageCode: string;
    storeId: string;
    withStore: boolean;
};

// This hook can be used by the operations to get common variables so they won't need to be supplied for each hook.
const useGetCommonVariables = (): CommonVariables => {
    const { language: languageCode } = useLocale();
    const { preferredStore } = useUserPreferences();
    const withStore = !!preferredStore;

    return useMemo(
        () => ({
            languageCode,
            withStore,
            storeId: preferredStore ?? '',
        }),
        [languageCode, preferredStore, withStore]
    );
};

export default useGetCommonVariables;
