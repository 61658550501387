import React from 'react';

import Toast from 'skapa/Toast';

import { useToast } from 'components/Toast';

/**
 * Toasts only support 1 toast visible at the same time. In situations where
 * multiple toasts are displayed they will be overlapping
 */
const ToastNotifications: React.FC = () => {
    const { toast, removeToast } = useToast();
    const onCloseRequest = () => {
        if (toast?.onTimeout) {
            toast.onTimeout();
        }
        removeToast();
    };

    return (
        <Toast
            data-testid={toast?.name}
            text={toast?.text ?? ''}
            actionButtonText={toast?.actionButtonText}
            actionClick={() => toast?.actionClick && toast.actionClick()}
            isOpen={toast !== null}
            onCloseRequest={onCloseRequest}
        />
    );
};

export default ToastNotifications;
