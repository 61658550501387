import React, { Fragment, ReactElement, useCallback } from 'react';

import defaultLanguage from '../phrase/en-XZ.json';
import useLocale from './useLocale';
import { QueryClient, useQuery } from '@tanstack/react-query';
import {
    getLocaleFromPath,
    getMarketData,
    MarketData,
} from '@utils/getLocaleFromPath';
import { apiLocalhost } from '@utils/settings';
import axios from 'axios';
import { GetServerSidePropsContext } from 'next';

export type TranslationsData = {
    [key: string]: string;
};

export type PhraseKeys = keyof typeof defaultLanguage;

const findVars = /(\{[\S]+\})/g;

export const fetchTranslations = async (
    localeData: MarketData
): Promise<{ [key in PhraseKeys]: string }> => {
    let host;
    if (typeof window === 'undefined') {
        // If we're on the server, then request ourself.
        host = apiLocalhost;
    } else {
        host = window.location.origin;
    }
    const url = `${host}/${localeData.country}/${localeData.language}/${
        localeData.prefix
    }/api/translations?language=${
        localeData.language
    }&country=${localeData.country.toUpperCase()}`;
    const res = await axios.get(url);
    return res.data;
};

const useTranslations = () => {
    const localeData = useLocale();
    const { data: translations } = useQuery(
        ['translations', localeData.ikeaMarketName],
        () => fetchTranslations(localeData),
        {
            staleTime: Infinity, // Always consider translations data to be fresh in favor of SSR
        }
    );

    const warn = useCallback(
        (id: string, warnMessage: string) => {
            console.log(
                `hooks/useTranslations: [${localeData.locale}][${id}] ${warnMessage}`
            );
        },
        [localeData.locale]
    );

    const translate = useCallback(
        (id: PhraseKeys) => {
            if (!translations) {
                warn(id, 'No translations loaded');
                return '';
            }
            const str = translations[id];

            if (!str) {
                warn(id, 'No translation found');
            }
            return str ?? '';
        },
        [translations, warn]
    );

    type Args = { [key: string]: string };
    const translateReplace = (id: PhraseKeys, args: Args) => {
        const str = translate(id);
        // 'a string with {a} variable' -> ['a string with ', '{a}', ' variable']
        const segments = str.split(findVars).filter(a => a);
        const response = segments.map(segment => {
            if (segment.startsWith('{')) {
                // '{var}' -> 'var'
                const key = segment.replace('{', '').replace('}', '');
                return args[key] !== undefined ? args[key] : segment;
            } else {
                return segment;
            }
        });
        return response.join('');
    };

    const translateReplaceWithFallback = (
        id: PhraseKeys,
        args: Args,
        fallback: PhraseKeys
    ) => translateReplace(id, args) ?? translate(fallback);

    const replaceWithElement = (
        id: PhraseKeys,
        args: { [key: string]: string | ReactElement }
    ) => (
        <>
            {translate(id)
                .split(findVars) // 'a string with {a} variable' -> ['a string with ', '{a}', ' variable']
                .filter(Boolean)
                .map((segment, i) => {
                    const key = segment.startsWith('{')
                        ? segment.replace(/{|}/g, '')
                        : '';
                    return <Fragment key={i}>{args[key] ?? segment}</Fragment>;
                })}
        </>
    );

    return {
        translate,
        translateReplace,
        translateReplaceWithFallback,
        replaceWithElement,
    };
};

export default useTranslations;

// Meant to be used in getServerSideProps
export const prefetchTranslations = async (
    ctx: GetServerSidePropsContext,
    queryClient: QueryClient
) => {
    const { country, language } = getLocaleFromPath(ctx.req?.url ?? '');
    const marketKey = `${country}${language}`;
    await queryClient.prefetchQuery(
        ['translations', marketKey],
        () => {
            return fetchTranslations(getMarketData(marketKey));
        },
        {
            staleTime: 1000 * 60 * 5, // Translations are invalidated after 5 minutes
        }
    );
};
