import { useMemo } from 'react';

import * as Sentry from '@sentry/react';
import {
    AddItemsMutationVariables,
    CreateListMutationVariables,
    GetListsQuery,
    MoveListsMutationVariables,
    RemoveItemsMutationVariables,
    RemoveListMutationVariables,
    RenameListMutationVariables,
    SetCollectedMutationVariables,
    SetSelectedMutationVariables,
    UpdateItemsMutationVariables,
    useAddItemsMutation,
    useCreateListMutation,
    useMoveListsMutation,
    useRemoveItemsMutation,
    useRemoveListMutation,
    useRenameListMutation,
    useSetCollectedMutation,
    useSetSelectedMutation,
    useUpdateItemsMutation,
} from './reactQueryHooks';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

import useGetCommonVariables, {
    CommonVariables,
} from 'apis/list/useGetCommonVariables';
import { List, Lists } from 'apis/list/useGetLists';

import {
    logCollected,
    logCreateList,
    logRenameList,
    logUpdateQuantity,
} from 'utils/analytics';
import { useOptimizely } from 'utils/optimizely/useOptimizely';

// Omit these common variables, we provide them inside the hooks instead
type OmitCommon<T> = Omit<T, keyof CommonVariables>;

const updateOrReplaceLists = <T extends { list: List } | { lists: Lists }>(
    data: T,
    old: GetListsQuery | undefined
) => {
    if (!data) return { lists: [] };
    return {
        lists:
            'lists' in data
                ? data.lists
                : [
                      data.list,
                      ...(old?.lists.filter(
                          list => data.list.listId !== list.listId
                      ) || []),
                  ],
    };
};

const handleSuccess = <T extends { list: List } | { lists: Lists }>(
    data: T,
    queryClient: QueryClient,
    commonVariables: CommonVariables
) => {
    queryClient.setQueryData(
        ['GetLists', commonVariables],
        (old: GetListsQuery | undefined) => updateOrReplaceLists(data, old)
    );
};

export const useAddItems = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useAddItemsMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    return {
        addItems: (variables: OmitCommon<AddItemsMutationVariables>) => {
            mutateAsync({
                ...commonVariables,
                ...variables,
            });
        },
        isLoading,
    };
};

export const useCreateList = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync } = useCreateListMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    const { trackEvent } = useOptimizely();
    return {
        createList: async (
            variables: OmitCommon<CreateListMutationVariables>
        ) => {
            const data = await mutateAsync({
                ...commonVariables,
                ...variables,
            });
            logCreateList(data.list.listId);
            trackEvent('favourites_lists_created');
            return data;
        },
    };
};

export const useMoveLists = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync } = useMoveListsMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    return {
        moveLists: (variables: OmitCommon<MoveListsMutationVariables>) =>
            mutateAsync({
                ...commonVariables,
                ...variables,
            }),
    };
};

export const useMoveItemsToAnotherList = () => {
    const { addItems, isLoading: isAddItemsLoading } = useAddItems();
    const { removeItems, isLoading: isRemoveItemsLoading } = useRemoveItems();
    const { trackEvent } = useOptimizely();
    return {
        moveItemsToAnotherList: async (
            items: Array<{ itemNo: string; quantity: number }>,
            targetListId: string,
            sourceListId: string
        ) => {
            try {
                addItems({
                    items,
                    listId: targetListId,
                    ignoreUnavailable: true,
                });
                await removeItems({
                    itemNos: items.map(item => item.itemNo),
                    listId: sourceListId,
                });
                trackEvent('favourites_move_to_other_list');
            } catch (e) {
                Sentry.captureException('could not move to other list');
            }
        },
        isLoading: isAddItemsLoading || isRemoveItemsLoading,
    };
};

export const useRemoveItems = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useRemoveItemsMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    return useMemo(
        () => ({
            removeItems: (
                variables: OmitCommon<RemoveItemsMutationVariables>
            ) =>
                mutateAsync({
                    ...commonVariables,
                    ...variables,
                }),
            isLoading,
        }),
        [commonVariables, isLoading, mutateAsync]
    );
};

export const useRemoveList = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync } = useRemoveListMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    const { trackEvent } = useOptimizely();

    return {
        removeList: async (
            variables: OmitCommon<RemoveListMutationVariables>
        ) => {
            await mutateAsync({
                ...commonVariables,
                ...variables,
            });
            trackEvent('favourites_lists_deleted');
        },
    };
};

export const useRenameList = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading } = useRenameListMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    return {
        renameList: async (
            variables: OmitCommon<RenameListMutationVariables>
        ) => {
            await mutateAsync({
                ...commonVariables,
                ...variables,
            });
            logRenameList(variables.listId);
        },
        isLoading,
    };
};

export const useSetCollected = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync } = useSetCollectedMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    return {
        setCollected: async (
            variables: OmitCommon<SetCollectedMutationVariables>,
            itemType: string,
            name: string // Item name for logging
        ) => {
            await mutateAsync({
                ...commonVariables,
                ...variables,
            });
            logCollected(
                variables.isCollected,
                name,
                variables.itemNo,
                variables.listId ?? '',
                itemType
            );
        },
    };
};

export const useSetSelected = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync } = useSetSelectedMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    return useMemo(
        () => ({
            setSelected: (
                variables: OmitCommon<SetSelectedMutationVariables>
            ) =>
                mutateAsync({
                    ...commonVariables,
                    ...variables,
                }),
        }),
        [commonVariables, mutateAsync]
    );
};

export const useUpdateItems = () => {
    const commonVariables = useGetCommonVariables();
    const queryClient = useQueryClient();
    const { mutateAsync } = useUpdateItemsMutation({
        onSuccess: data => handleSuccess(data, queryClient, commonVariables),
    });
    return useMemo(
        () => ({
            updateItems: async (
                variables: OmitCommon<UpdateItemsMutationVariables>,
                itemsAnalyticsData: Array<{
                    quantityDelta: number;
                    itemType: string;
                }>
            ) => {
                const data = await mutateAsync({
                    ...commonVariables,
                    ...variables,
                });
                const { items } = variables;
                // Ensure items is an array
                const itemsArray = items instanceof Array ? items : [items];
                const itemsWithQuantityDelta = itemsArray.map((item, i) => {
                    return {
                        ...item,
                        ...itemsAnalyticsData[i],
                    };
                });
                logUpdateQuantity(itemsWithQuantityDelta, data.list.listId);
                return data;
            },
        }),
        [commonVariables, mutateAsync]
    );
};
