import { useEffect, useMemo, useState } from 'react';

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { subscribe } from 'utils/window/pubsub';
import {
    clear as clearStore,
    get as getStore,
    set as setStore,
} from 'utils/window/store';

import useLocalStorage from 'hooks/useLocalstorage';
import { useSettings } from 'hooks/useSettings';

const useUserPreferences = () => {
    const [queryId, setQueryId] = useState<string | null>(null);
    const { includeVAT: includeVATmarketDefault } = useSettings();
    const { data: preferredStore } = useQuery(
        ['preferredStore', queryId], // Ensures query will automatically refetch when store changes.
        () => getStore(),
        {
            onError: err => Sentry.captureException(err),
            keepPreviousData: true,
        }
    );

    const [showPricesWithVAT, setShowPricesWithVAT] = useLocalStorage<boolean>(
        'include-vat',
        Boolean(includeVATmarketDefault)
    );

    // When user sets a new preferred store location, update our state to reflect it.
    // (The local storage is updated by the nav team so no need to set it)
    useEffect(() => {
        subscribe<string>('NAVIGATION/STORE_SET', id => {
            setQueryId(id);
        });
    }, []);

    return useMemo(
        () => ({
            setPreferredStore: (id: string | null) => {
                setQueryId(id);
                // When the user updates preferred store, also set it for the entire IKEA market
                if (!id) {
                    clearStore();
                } else {
                    setStore(id);
                }
            },
            preferredStore,
            setShowPricesWithVAT,
            showPricesWithVAT,
        }),
        [preferredStore, setShowPricesWithVAT, showPricesWithVAT]
    );
};

export default useUserPreferences;
