import { useEffect, useState } from 'react';

import { getMarketData } from '@utils/getLocaleFromPath';
import { useRouter } from 'next/router';

export default function useLocale() {
    const { query } = useRouter();
    const [currentLocale, setCurrentLocale] = useState(
        `${query.cc ?? 'gb'}${query.ll ?? 'en'}`
    );
    useEffect(() => {
        if (query.cc && query.ll) {
            // Todo check allowed markets from json file
            setCurrentLocale(`${query.cc}${query.ll}`);
        }
    }, [query.cc, query.ll]);
    return getMarketData(currentLocale);
}
