import { useCallback, useMemo } from 'react';

import * as Sentry from '@sentry/react';
import useLocale from './useLocale';
import { Dedupe } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { getCookie } from 'utils/cookie';
import getUserId from 'utils/get-userid-from-token';
import { consentCategories } from 'utils/optanon';
import { version } from 'utils/settings';

import { useSettings } from 'hooks/useSettings';

// https://stackoverflow.com/a/13653180/17297798 - Matches the GUID used for favourite collection ID:s (listId)
const guidRegex =
    /([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/;
const receiveShareRegex = /\/receive-share\/([0-9:,]*)/;
const sampleRate = 0.5;

const useMonitoring = () => {
    const { cartEndpoint, listEndpoint, sentryDsn } = useSettings();
    const { country, language } = useLocale();
    const initSentry = useCallback(() => {
        if (
            sentryDsn &&
            (window.ikea?.cookieConsent === undefined ||
                window.ikea?.cookieConsent?.hasConsent(
                    consentCategories.performanceCookies
                ) ||
                window.location.host.includes('localhost') ||
                window.location.host.includes('ikeadt'))
        ) {
            Sentry.setTag('country', country);
            Sentry.setTag('language', language);
            Sentry.setTag('version', 'next');
            Sentry.init({
                dsn: sentryDsn,
                denyUrls: [/byside.com/, /local-extensions/],
                allowUrls: [cartEndpoint, listEndpoint, window?.location.href],
                environment:
                    window.location.host.includes('localhost') ||
                    window.location.host.includes('ikeadt')
                        ? 'dev'
                        : 'production',
                release: `v${version}`,
                sampleRate,
                beforeSend: (e: Sentry.Event) => {
                    if (!e.user) {
                        const guestToken = getCookie('guest');
                        const authToken = getCookie('auth');
                        const token = authToken ? authToken : guestToken;
                        if (token === '') {
                            e.user = { id: 'Unknown user' };
                        } else {
                            e.user = { id: getUserId(token) };
                        }
                    }
                    return e;
                },
                tracesSampler: () => 0.1,
                attachStacktrace: true,
                autoSessionTracking: true,
                normalizeDepth: 10,
                integrations: [
                    new Dedupe(),
                    new TracingIntegrations.BrowserTracing({
                        beforeNavigate: context => {
                            return {
                                ...context,
                                name: location.pathname
                                    .replace(`/${country}/${language}/`, '')
                                    .replace('favorites', 'favourites')
                                    .replace(guidRegex, '[guid]')
                                    .replace(
                                        receiveShareRegex,
                                        '/receive-share/[ids]'
                                    ),
                            };
                        },
                    }),
                ],
            });
        }
    }, [cartEndpoint, country, language, listEndpoint, sentryDsn]);

    return useMemo(
        () => ({
            setContext: Sentry.setContext.bind(Sentry),
            initSentry,
        }),
        [initSentry]
    );
};

export default useMonitoring;
