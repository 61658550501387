import React, { useState } from 'react';

import '../styles/globals.scss';
import {
    DehydratedState,
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { mockConsent } from '@utils/mock-consent';
import type { AppProps } from 'next/app';

import { OptimizelyProvider } from 'utils/optimizely/OptimizelyWrapper';
import { shouldMockProd } from 'utils/settings';

import PageContainer from 'skapa/PageContainer';

import ComponentWrapper from 'components/ComponentWrapper';
import { ToastProvider } from 'components/Toast';
import ToastNotifications from 'components/ToastNotifications';

if (shouldMockProd) {
    mockConsent();
}

const CustomApp = ({
    Component,
    pageProps,
    err, // This is not public Nextjs API...
    router,
}: AppProps & {
    err: unknown;
    pageProps: AppProps['pageProps'] & { dehydratedState: DehydratedState };
}) => {
    const [queryClient] = useState(() => new QueryClient());
    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps?.dehydratedState}>
                <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                />
                <ToastProvider>
                    <OptimizelyProvider>
                        <PageContainer>
                            <ToastNotifications />
                            <ComponentWrapper
                                Component={Component}
                                pageProps={pageProps}
                                router={router}
                                err={err}
                            />
                        </PageContainer>
                    </OptimizelyProvider>
                </ToastProvider>
            </Hydrate>
        </QueryClientProvider>
    );
};

export default CustomApp;
