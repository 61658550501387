import { useCallback, useEffect, useMemo, useState } from 'react';

const TOAST_TIMEOUT = 4000;

type ToastMessage = {
    name: string;
    text: string;
    actionClick?: Function;
    actionButtonText?: string;
    onTimeout?: Function;
};

const useToast = () => {
    const [toast, setToast] = useState(null as ToastMessage | null);

    useEffect(() => {
        let timeoutId: number;
        if (typeof window === 'undefined') return;
        if (toast && typeof window !== 'undefined') {
            timeoutId = window.setTimeout(() => {
                if (toast.onTimeout) {
                    toast.onTimeout();
                }
                setToast(null);
            }, TOAST_TIMEOUT);
        }
        return () => clearTimeout(timeoutId);
    }, [toast]);

    const showToast = useCallback(
        function showToast(options: ToastMessage) {
            if (toast?.name !== options.name && toast?.onTimeout) {
                toast.onTimeout();
            }
            setToast(options);
        },
        [toast]
    );

    useEffect(() => {
        if (!toast) return;
        if (typeof window === 'undefined') return;
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (toast.onTimeout) toast.onTimeout();
            event.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () =>
            window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [toast]);

    const removeToast = useCallback(function removeToast() {
        setToast(null);
    }, []);

    const useToast = useMemo(
        () => ({
            showToast,
            removeToast,
            toast,
        }),
        [showToast, removeToast, toast]
    );

    return useToast;
};

export default useToast;
