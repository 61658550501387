import React, { createContext, useCallback, useEffect, useRef } from 'react';

import { Wrapper } from './Wrapper';
import { useSettings } from '@hooks/useSettings';

type OptimizelyProviderProps = {
    children: React.ReactNode;
};

const options = {};

export const OptimizelyContext = createContext<() => Wrapper | null>(
    () => null
);

export const OptimizelyProvider: React.FC<OptimizelyProviderProps> = ({
    children,
}) => {
    const { optimizelyKey } = useSettings();
    const wrapper = useRef<Wrapper | null>(null);

    const getWrapper = useCallback(() => wrapper.current, []);

    const init = useCallback(async () => {
        if (wrapper.current) {
            return;
        }
        if (!window.ikea?.experiment?.init) {
            console.warn('missing window.ikea.experiment.init');
        }
        const _wrapper = await window.ikea.experiment.init(
            optimizelyKey,
            options
        );
        wrapper.current = _wrapper;

        // This is a way to force a variation for a specific flag
        const url = new URL(document.location.href);
        const flagKey = url.searchParams.get('optimizely_flag');
        const ruleKey = url.searchParams.get('optimizely_rule');
        const variationKey = url.searchParams.get('optimizely_variation');

        if (flagKey && variationKey)
            _wrapper.setForcedDecision(
                flagKey,
                ruleKey as string,
                variationKey
            );
    }, [optimizelyKey]);

    useEffect(() => {
        init();
    }, [init]);
    if (typeof window !== 'undefined') {
        window.ikea?.pubsub?.subscribe('ikeaCookieConsent/changed', init);
    } else {
        console.warn('detected server, skipping subscribe');
    }
    return (
        <OptimizelyContext.Provider value={getWrapper}>
            {children}
        </OptimizelyContext.Provider>
    );
};
