/**
 * Warning - Hard coded settings!
 *
 * Avoid keeping settings here - they should normally be in the env files.
 * The settings below should be used mainly by _document.tsx.
 */

/* Build information */
export const version: string = process.env.NEXT_PUBLIC_VERSION ?? 'dev';

/* For Next to access its API through localhost */
export const apiLocalhost = `http://localhost:${process.env.PORT || 3000}`;

/* Phrase keys */
const makePhraseHeaders = () => {
    const phraseAuthToken =
        'fd836a8fe204d7c7ab69bc8ece493c3122c9e52d63dca33b7fe97479e0cbac7e';
    const phraseTeamSlackEmail =
        'dt-sp-trojan-pigs-fe-aaaackouqvtcckr2v4zx3ha5qq@ikea.org.slack.com';
    const phraseUserAgent = `IKEA Favourites BFF (${
        process.env.NEXT_PUBLIC_IKEA_ENV === 'production'
            ? phraseTeamSlackEmail
            : process.env.NEXT_PUBLIC_IKEA_ENV ?? 'localhost'
    })`;
    return {
        Authorization: `token ${phraseAuthToken}`,
        'User-Agent': phraseUserAgent,
    };
};
export const phraseHeaders = makePhraseHeaders();

/* Selling range */
export const sellingRangeClientId = 'af1c03f0-de94-49c7-a690-8a41059c695b';
export const sellingRangeEndpoint = 'https://api.ingka.ikea.com/salesitem';

/* ESI logic */
const esiHosts: Record<string, string> = {
    'pr-cte': 'https://www.cte.ikeadt.com',
    'pr-ppe': 'https://www.ikea.com',
    cte: 'https://www.cte.ikeadt.com',
    localhost: 'https://www.ikea.com', // We can't use the Fastly host b/c of CORS?
    ppe: 'https://fragments.cdn.ingka.com',
    production: 'https://fragments.cdn.ingka.com',
};

export const esiBase =
    esiHosts[process.env.NEXT_PUBLIC_IKEA_ENV ?? 'localhost'];
export const shouldRenderEsi =
    !process.env.NEXT_PUBLIC_IKEA_ENV || !!process.env.RENDER_ESI;
export const shouldMockProd = process.env.NEXT_PUBLIC_IKEA_ENV !== 'production';
