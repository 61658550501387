import { logEcommerceEvent, logEvent, productId } from './analytics';

import { AddToCartSource } from 'apis/cart/operations';
import { GetListsQuery, List } from 'apis/list/reactQueryHooks';

type cartCustomType = {
    params: {
        items: {
            id: string;
            price?: number;
            quantity: number;
        }[];
    };
    custom: {
        cart_value_local: string;
        cart_id: string;
    };
};

export const logAddToCart = (
    cartCustomValues: cartCustomType,
    source: AddToCartSource
) => {
    logEcommerceEvent({
        label: source,
        name: 'add_to_cart',
        ...cartCustomValues,
    });
};

export const logUpdateQuantity = (
    items: {
        itemNo: string;
        quantityDelta: number;
        itemType: string;
    }[],
    listId: string
) =>
    items.forEach(({ quantityDelta, itemNo, itemType }) => {
        const logAction =
            quantityDelta > 0
                ? 'wishlist_increase_amount'
                : 'wishlist_decrease_amount';
        logEvent({
            category: 'ecommerce',
            action: logAction,
            custom: {
                list_id: listId,
                item_number_event: itemNo,
                item_type_event: itemType,
                product_quantity: quantityDelta,
            },
        });
    });

export const logCollected = (
    isCollected: boolean,
    name: string,
    itemNo: string,
    listId: string,
    itemType: string
) =>
    isCollected &&
    logEvent({
        category: 'ecommerce',
        action: 'cross_off_checklist',
        label: productId(name, itemNo),
        custom: {
            list_id: listId,
            item_number_event: itemNo,
            item_type_event: itemType,
        },
    });

export const logRemovedItems = (
    items: GetListsQuery['lists'][number]['retail']['items'],
    listId: string
) => {
    items.forEach(item => {
        logEvent({
            category: 'ecommerce',
            action: 'remove_from_wishlist',
            custom: {
                list_id: listId,
                item_number_event: item.itemNo,
                item_type_event: item.type,
                product_quantity: item.quantity,
            },
        });
    });
};

export const logViewChange = (
    view: string,
    listId: string,
    storeId?: string
) => {
    logEvent({
        category: 'ecommerce',
        action:
            view === 'store' ? 'wishlist_store_mode' : 'wishlist_online_mode',
        custom: {
            list_id: listId,
            ...(view === 'store' && storeId && { local_store: storeId }),
        },
    });
};

export const logSelectStore = (storeId: string, listId: string) =>
    logEvent({
        category: 'ecommerce',
        action: 'wishlist_store_mode',
        custom: { list_id: listId, local_store: storeId },
    });

export const logCreateList = (listId: string) => {
    logEvent({
        category: 'ecommerce',
        action: 'create_list',
        custom: { list_id: listId },
    });
};
export const logRenameList = (listId: string) =>
    logEvent({
        category: 'ecommerce',
        action: 'rename_list',
        custom: { list_id: listId },
    });
export const logRemoveList = (quantity: number, listId: string) =>
    logEvent({
        category: 'ecommerce',
        action: 'remove_list',
        label: String(quantity),
        custom: { list_id: listId },
    });

export const logReceivedList = () => {
    const source = new URLSearchParams(window.location.search).get('source');
    logEvent({
        category: 'favourites',
        action: 'view_received_list',
        label: source || undefined,
    });
};

const concat = (acc: string, curr: string) =>
    acc === '' ? curr : `${acc},${curr}`;

export const logListView = (
    view: 'retail' | 'store',
    listId: string,
    list: List
) =>
    logEvent({
        category: 'ecommerce',
        action: 'view_list',
        label: view === 'store' ? 'buy_in_store' : 'shop_online',
        custom: {
            list_id: listId,
            ...(list?.retail?.items &&
                list.retail.items.reduce(
                    (acc, curr) => ({
                        item_number_event: concat(
                            acc.item_number_event,
                            `${curr.itemNo}`
                        ),
                        product_quantity: concat(
                            acc.product_quantity,
                            `${curr.quantity}`
                        ),
                        item_type_event: concat(
                            acc.item_type_event,
                            `${curr.type}`
                        ),
                    }),
                    {
                        item_number_event: '',
                        product_quantity: '',
                        item_type_event: '',
                    }
                )),
        },
    });

export const logRemovedInspiration = (ids: Array<string>) =>
    logEvent({
        category: 'inspiration',
        action: 'removed_from_inspiration',
        custom: {
            component_id: ids,
            component_type: 'inspirational-collection',
        },
    });

export const logGetInspiredFromFavouritesClick = () => {
    logEvent({
        category: 'inspiration',
        action: 'get_inspired_from_favourites_click',
    });
};
