import {
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';

import { useFetchData } from 'apis/list/fetcher';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

/** The necessary input when adding items. */
export type AddItemInput = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The quantity of items. */
    quantity: Scalars['Int'];
};

/** The base interface and common denominator for an article. */
export type BaseArticle = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** Packages. */
    packages: Array<Package>;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantityPerItem of an item. */
    quantityPerItem: Scalars['Int'];
    /** Weight per article. */
    weight: Weight;
};

/** The base interface and common denominator for an item. */
export type BaseItem = {
    /** UNIX time in seconds for when the item was added to the list. */
    addedAt: Scalars['Int'];
    /** The family price. */
    familyPrice: Maybe<FamilyPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a breath taking item? */
    isBreathTakingItem: Scalars['Boolean'];
    /** Is this item communicated online? */
    isCommunicatedOnline: Scalars['Boolean'];
    /** Is the item a family item? */
    isFamilyItem: Scalars['Boolean'];
    /** Is the item a new item? */
    isNew: Scalars['Boolean'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** Product information. */
    product: Maybe<Product>;
    /** The quantity of an item. */
    quantity: Scalars['Int'];
    /** The regular price. */
    regularPrice: Maybe<RegularPrice>;
    /** The item type */
    type: ItemType;
};

/** The context type. */
export type Context = {
    __typename?: 'Context';
    /** Is user anonymous or not? */
    isAnonymous: Scalars['Boolean'];
    /** The request id. */
    requestId: Scalars['String'];
    /** The user id. */
    userId: Scalars['ID'];
};

/** The department in the store where an item can be found. */
export type Department = {
    __typename?: 'Department';
    /** The id of the department. */
    id: Scalars['String'];
    /** The name. */
    name: Scalars['String'];
};

/** The depot location where an item can be found in store. */
export type Depot = {
    __typename?: 'Depot';
    /** The aisle location. */
    aisle: Scalars['String'];
    /** The bin location. */
    bin: Scalars['String'];
};

/** The discount. */
export type Discount = {
    __typename?: 'Discount';
    /** The discount amount. */
    amount: Scalars['Float'];
    /** The discount code. */
    code: Maybe<Scalars['String']>;
    /** The discount description. */
    description: Maybe<Scalars['String']>;
    /** The discount kind. */
    kind: DiscountKind;
};

/** The discount kind. */
export enum DiscountKind {
    /** Item discount. */
    Item = 'ITEM',
    /** Order discount. */
    Order = 'ORDER',
}

/** The display unit. */
export type DisplayUnit = {
    __typename?: 'DisplayUnit';
    /** The imperial measurement details. */
    imperial: Maybe<Imperial>;
    /** The metric measurement details. */
    metric: Maybe<Metric>;
    /** The unit type of the package, e.g "Piece", "Meterware". */
    type: UnitType;
};

/** The family price. */
export type FamilyPrice = {
    __typename?: 'FamilyPrice';
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The unit details. */
    unit: FamilyUnitPrice;
};

/** The family total price. */
export type FamilyTotalPrice = {
    __typename?: 'FamilyTotalPrice';
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
    /** The total savings excluding discount. */
    totalSavingsExclDiscount: Savings;
    /** The total savings including discount. */
    totalSavingsInclDiscount: Savings;
};

/** The family unit price. */
export type FamilyUnitPrice = {
    __typename?: 'FamilyUnitPrice';
    /** The price to pay excluding tax. */
    exclTax: Scalars['Float'];
    /** The price to pay including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** The tax to pay. */
    tax: Maybe<Scalars['Float']>;
    /** Valid from (descriptive date field). */
    validFrom: Maybe<Scalars['String']>;
    /** Valid to (descriptive date field). */
    validTo: Maybe<Scalars['String']>;
};

/** The fees, e.g. "eco", "weee" */
export type Fees = {
    __typename?: 'Fees';
    /** ECO fee. */
    eco: Maybe<Scalars['Float']>;
    /** Fee for electrical equipment in EU (Waste Electrical and Electronic Equipment). */
    weee: Maybe<Scalars['Float']>;
};

/** The Image details of a product. */
export type Image = {
    __typename?: 'Image';
    /** The filename. */
    filename: Scalars['String'];
    /** The height. */
    height: Scalars['Int'];
    /** The quality. */
    quality: Scalars['String'];
    /** The type. */
    type: Scalars['String'];
    /** The url. */
    url: Scalars['String'];
    /** The width. */
    width: Scalars['Int'];
};

/** The imperial measurement details. */
export type Imperial = {
    __typename?: 'Imperial';
    /** The unit of measurement. */
    unit: Scalars['String'];
    /** The value of measurement. */
    value: Scalars['String'];
};

/**
 * Inspiration is a container of images from the inspiration feed.
 *
 * Note: The inspiration data type is an experimental feature and may have breaking changes without warning or
 * be removed entirely in future releases.
 */
export type Inspiration = {
    __typename?: 'Inspiration';
    /** The inspiration id, e.g. '01094A6D-15AE-4513-BECC4641FDC64991'. */
    id: Scalars['ID'];
    /** Short version of the inspiration id. */
    shortId: Scalars['String'];
    /** Url safe name for the inspiration. */
    slug: Scalars['String'];
    /** The url of the inspiration. */
    url: Scalars['String'];
};

/** The type of an item. */
export enum ItemType {
    /** ART type. */
    Art = 'ART',
    /** SPR type. */
    Spr = 'SPR',
}

/**
 * A list is a container of items. It is possible to retrieve online and store prices,
 * as well as store availability and location on each item.
 *
 * Note! If you do a mutation and the response type is a List, returning just the
 * context requires no extra resources to be fetched from the server. So if the response is of no importance to you,
 * other than knowing if an operation was successful or not, the context alone is what you want to set as return value.
 */
export type List = {
    __typename?: 'List';
    /** The context. */
    context: Context;
    /** A boolean value that indicate if the list is the currently selected list. */
    isSelected: Scalars['Boolean'];
    /** The id. */
    listId: Scalars['ID'];
    /** The name. */
    name: Scalars['String'];
    /** The quantity. */
    quantity: Scalars['Int'];
    /** The retail list. Input languageCode are mandatory to get the list for that specific retail. */
    retail: RetailList;
    /** The store list. Input parameters storeId and languageCode are mandatory to get the list for that specific store. */
    store: StoreList;
    /**
     * The date when the list was last modified. Format is ISO 8601 and the time zone is coordinated universal
     * time (UTC), YYYY-MM-DDThh:mm:ssZ, e.g. "2019-02-06T21:56:00Z".
     */
    updated: Scalars['String'];
};

/**
 * A list is a container of items. It is possible to retrieve online and store prices,
 * as well as store availability and location on each item.
 *
 * Note! If you do a mutation and the response type is a List, returning just the
 * context requires no extra resources to be fetched from the server. So if the response is of no importance to you,
 * other than knowing if an operation was successful or not, the context alone is what you want to set as return value.
 */
export type ListRetailArgs = {
    languageCode: Scalars['String'];
};

/**
 * A list is a container of items. It is possible to retrieve online and store prices,
 * as well as store availability and location on each item.
 *
 * Note! If you do a mutation and the response type is a List, returning just the
 * context requires no extra resources to be fetched from the server. So if the response is of no importance to you,
 * other than knowing if an operation was successful or not, the context alone is what you want to set as return value.
 */
export type ListStoreArgs = {
    languageCode: Scalars['String'];
    storeId: Scalars['String'];
};

/** The store location of an item. */
export type Location = {
    __typename?: 'Location';
    /** The department in the store where the item can be found. */
    department: Maybe<Department>;
    /** The depot location where the item can be found in store. */
    depot: Maybe<Depot>;
};

/** The measurements. */
export type Measurement = {
    __typename?: 'Measurement';
    /** The imperial measurement value. */
    imperial: Maybe<Scalars['String']>;
    /** The metric measurement value. */
    metric: Maybe<Scalars['String']>;
};

/** The metric measurement details. */
export type Metric = {
    __typename?: 'Metric';
    /** The unit of measurement. */
    unit: Scalars['String'];
    /** The value of measurement. */
    value: Scalars['String'];
};

/**
 * MinimalInspiration is a container of inspirations from the inspiration feed.
 *
 * Note! The inspiration data type is an experimental feature and may have breaking changes without warning or
 * be removed entirely in future releases.
 * Built to be used by list-agent for polling the current state.
 */
export type MinimalInspiration = {
    __typename?: 'MinimalInspiration';
    /** The inspiration id, e.g. '01094A6D-15AE-4513-BECC4641FDC64991'. */
    id: Scalars['ID'];
};

export type MinimalItem = {
    __typename?: 'MinimalItem';
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The quantity of an item. */
    quantity: Scalars['Int'];
};

export type MinimalList = {
    __typename?: 'MinimalList';
    /** A boolean value that indicate if the list is the currently selected list. */
    isSelected: Scalars['Boolean'];
    /** The items in the list. Not filtered in any way, so might contain unavailable items. */
    items: Array<MinimalItem>;
    /** The id. */
    listId: Scalars['ID'];
    /** The name. */
    name: Scalars['String'];
    /**
     * The date when the list was last modified. Format is ISO 8601 and the time zone is coordinated universal
     * time (UTC), YYYY-MM-DDThh:mm:ssZ, e.g. "2019-02-06T21:56:00Z".
     */
    updated: Scalars['String'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type Mutation = {
    __typename?: 'Mutation';
    /**
     * Adds inspirations to the user's inspirational feed.
     *
     * Note: The inspiration mutations are experimental feature and may have breaking changes without warning or
     * be removed entirely in future releases.
     *
     * --- ERROR CODES ---
     * INSPIRATIONS_NOT_FOUND: Inspirations not found.
     * INTERNAL_ERROR: An internal error has occurred.
     * MAX_CREATE_LIMIT: The maximum number of images was reached.
     */
    addInspirations: Array<Inspiration>;
    /**
     * Adds items to the list.
     *
     * --- ERROR CODES ---
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_ITEM_NUMBER: Invalid or unavailable item number(s) in request.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_UNIQUE: Identical item numbers in request.
     * LIST_NOT_FOUND: The list id was not found.
     * MAX_CREATE_LIMIT: The maximum number of items was reached.
     */
    addItems: List;
    /**
     * Creates a new list.
     *
     * --- ERROR CODES ---
     * EMPTY_NAME: Can not create a list with empty name.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MAX_CREATE_LIMIT: The maximum number of lists was reached.
     * MAX_NAME_LENGTH: The name is too long.
     * NAME_CONFLICT: The name already exists.
     */
    createList: List;
    /**
     * Moves inspirations from a guest user to a logged in user in the same retail id.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * MAX_CREATE_LIMIT: The maximum number of images was reached.
     * PERMISSION_DENIED: Cannot move inspiration. Source user should be a guest user.
     * UNAVAILABLE_INSPIRATIONS_REMOVED: Removed unavailable inspirations.
     */
    moveInspirations: Array<Inspiration>;
    /**
     * Moves lists from a guest user to a logged in user.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MAX_CREATE_LIMIT: The maximum number of lists was reached.
     * PERMISSION_DENIED: Cannot copy items from logged in user to guest user.
     */
    moveLists: Array<List>;
    /**
     * Removes inspirations from the user's inspirational feed.
     *
     * Note: The inspiration mutations are experimental feature and may have breaking changes without warning or
     * be removed entirely in future releases.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * UNAVAILABLE_INSPIRATIONS_REMOVED: Removed unavailable inspirations.
     */
    removeInspirations: Array<Inspiration>;
    /**
     * Remove items from the list.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_FOUND: Item number(s) could not be found.
     * LIST_NOT_FOUND: The list id was not found.
     */
    removeItems: List;
    /**
     * Removes the list.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * LIST_NOT_FOUND: The list id was not found.
     */
    removeList: Array<List>;
    /**
     * Renames the list.
     *
     * --- ERROR CODES ---
     * EMPTY_NAME: Can not create a list with empty name.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * MAX_NAME_LENGTH: The name is too long.
     * NAME_CONFLICT: The name already exists.
     */
    renameList: List;
    /**
     * Sets if an item is collected.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_FOUND: Item number(s) could not be found.
     * LIST_NOT_FOUND: The list id was not found.
     */
    setCollected: List;
    /**
     * Sets which list is currently selected.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * LIST_NOT_FOUND: The list id was not found.
     */
    setSelected: Array<List>;
    /**
     * Update items in the list.
     *
     * --- ERROR CODES ---
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_ITEM_NUMBER: Invalid or unavailable item number(s) in request.
     * INVALID_LANGUAGE_ID: Could not find language code.
     * INVALID_STORE_ID: Could not find store id.
     * ITEM_NUMBER_NOT_FOUND: Item number(s) could not be found.
     * ITEM_NUMBER_NOT_UNIQUE: Identical item numbers in request.
     * LIST_NOT_FOUND: The list id was not found.
     */
    updateItems: List;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationAddInspirationsArgs = {
    ids: Array<Scalars['ID']>;
    languageCode: Scalars['String'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationAddItemsArgs = {
    ignoreUnavailable?: InputMaybe<Scalars['Boolean']>;
    items: Array<AddItemInput>;
    listId: InputMaybe<Scalars['ID']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationCreateListArgs = {
    name: Scalars['String'];
    setSelected: InputMaybe<Scalars['Boolean']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationMoveInspirationsArgs = {
    languageCode: Scalars['String'];
    sourceUserId: Scalars['ID'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationMoveListsArgs = {
    sourceUserId: Scalars['ID'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveInspirationsArgs = {
    ids: Array<Scalars['ID']>;
    languageCode: Scalars['String'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveItemsArgs = {
    itemNos: Array<Scalars['ID']>;
    listId: InputMaybe<Scalars['ID']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveListArgs = {
    listId: Scalars['ID'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRenameListArgs = {
    listId: Scalars['ID'];
    name: Scalars['String'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationSetCollectedArgs = {
    isCollected: Scalars['Boolean'];
    itemNo: Scalars['ID'];
    listId: InputMaybe<Scalars['ID']>;
    parentItemNo: InputMaybe<Scalars['ID']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationSetSelectedArgs = {
    listId: Scalars['ID'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationUpdateItemsArgs = {
    items: Array<UpdateItemInput>;
    listId: InputMaybe<Scalars['ID']>;
};

/** The Package. */
export type Package = {
    __typename?: 'Package';
    /** The id. */
    id: Scalars['Int'];
    /** The measurements. */
    measurements: Array<PackageMeasurement>;
};

/** The package measurements. */
export type PackageMeasurement = {
    __typename?: 'PackageMeasurement';
    /** The imperial text. */
    textImperial: Scalars['String'];
    /** The metric text. */
    textMetric: Scalars['String'];
    /** The type. */
    type: Scalars['String'];
    /** The type name. */
    typeName: Scalars['String'];
    /** The unit for imperial. */
    unitImperial: Scalars['String'];
    /** The unit for metric. */
    unitMetric: Scalars['String'];
    /** The imperial value. */
    valueImperial: Scalars['String'];
    /** The metric value. */
    valueMetric: Scalars['String'];
};

/** The current position based on GPS location. */
export type Position = {
    __typename?: 'Position';
    /** The latitude. */
    lat: Scalars['Float'];
    /** The longitude. */
    lon: Scalars['Float'];
};

/** The necessary input when finding a position. */
export type PositionInput = {
    /** The latitude. */
    lat: Scalars['Float'];
    /** The longitude. */
    lon: Scalars['Float'];
};

/** The price. */
export type Price = {
    __typename?: 'Price';
    /** The price excluding tax. */
    exclTax: Scalars['Float'];
    /** The price including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** The tax. */
    tax: Maybe<Scalars['Float']>;
    /** A list of tax details. */
    taxDetails: Array<TaxDetail>;
};

/** The product information. */
export type Product = {
    __typename?: 'Product';
    /** The product category */
    category: Maybe<Scalars['String']>;
    /** A short description of the product. */
    description: Maybe<Scalars['String']>;
    /** The package details." */
    displayUnit: Maybe<DisplayUnit>;
    /** The global name. */
    globalName: Scalars['String'];
    /** The Image details. */
    images: Array<Image>;
    /** The measurements. */
    measurements: Array<Measurement>;
    /** The name. */
    name: Scalars['String'];
    /**
     * The technical details.
     * @deprecated This information will be included in media
     */
    technicalDetails: Maybe<TechnicalDetails>;
    /** The product type */
    type: Scalars['String'];
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type Query = {
    __typename?: 'Query';
    /**
     * Lookup which context you have.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    context: Context;
    /**
     * Fetch user's inspirations from the inspirational feed.
     *
     * Note: The inspiration queries are experimental feature and may have breaking changes without warning or
     * be removed entirely in future releases.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * UNAVAILABLE_INSPIRATIONS_REMOVED: Removed unavailable inspirations.
     */
    inspirations: Array<Inspiration>;
    /**
     * Lookup a specific list with an id.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    list: List;
    /**
     * Lookup all lists.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    lists: Array<List>;
    /**
     * Fetch user's inspirations from the inspirational feed.
     *
     * Note! Does not query any downstream systems and gets the data only from the database.
     * Built to be used by list-agent for polling the current state.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    minimalInspirations: Array<MinimalInspiration>;
    /**
     * Returns core information regarding all your lists.
     * Does not query any downstream systems.
     * Built to be used by list-agent for polling the current state.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    minimalLists: Array<MinimalList>;
    /**
     * Lookup a specific store with an id.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_STORE_ID: Could not find store id.
     */
    store: Store;
    /**
     * Lookup all stores. Optional position input which will sort the stores from closest to
     * furthest away from that point.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    stores: Array<Store>;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryInspirationsArgs = {
    languageCode: Scalars['String'];
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryListArgs = {
    listId: InputMaybe<Scalars['ID']>;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryStoreArgs = {
    languageCode: Scalars['String'];
    storeId: Scalars['String'];
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryStoresArgs = {
    languageCode: Scalars['String'];
    position: InputMaybe<PositionInput>;
};

/** The regular price details. */
export type RegularPrice = {
    __typename?: 'RegularPrice';
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The unit details. */
    unit: RegularUnitPrice;
};

/** The regular total price. */
export type RegularTotalPrice = {
    __typename?: 'RegularTotalPrice';
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
};

/** The regular unit price. */
export type RegularUnitPrice = {
    __typename?: 'RegularUnitPrice';
    /** The price to pay excluding tax. */
    exclTax: Scalars['Float'];
    /** The price to pay including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** Is the price a lower price item? */
    isLowerPrice: Scalars['Boolean'];
    /** The previous price. */
    previousPrice: Maybe<Price>;
    /** The tax to pay. */
    tax: Maybe<Scalars['Float']>;
    /** Valid from (descriptive date field). */
    validFrom: Maybe<Scalars['String']>;
    /** Valid to (descriptive date field). */
    validTo: Maybe<Scalars['String']>;
};

/** Restock data. Used to determine when an item is expected to be back in stock. */
export type Restock = {
    __typename?: 'Restock';
    earliestDate: Scalars['String'];
    latestDate: Scalars['String'];
};

/** The retail article. */
export type RetailArticle = BaseArticle & {
    __typename?: 'RetailArticle';
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** Packages. */
    packages: Array<Package>;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantityPerItem of an item. */
    quantityPerItem: Scalars['Int'];
    /** Weight per article. */
    weight: Weight;
};

/** The retail item. */
export type RetailItem = BaseItem & {
    __typename?: 'RetailItem';
    /** UNIX time in seconds for when the item was added to the list. */
    addedAt: Scalars['Int'];
    /** The article. */
    articles: Array<RetailArticle>;
    /** The family price. */
    familyPrice: Maybe<FamilyPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a breath taking item? */
    isBreathTakingItem: Scalars['Boolean'];
    /** Is this item buyable online? */
    isBuyableOnline: Scalars['Boolean'];
    /** Is this item communicated online? */
    isCommunicatedOnline: Scalars['Boolean'];
    /** Is the item a family item? */
    isFamilyItem: Scalars['Boolean'];
    /** Is the item a new item? */
    isNew: Scalars['Boolean'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** Product information. */
    product: Maybe<Product>;
    /** The quantity of an item. */
    quantity: Scalars['Int'];
    /** The regular price. */
    regularPrice: Maybe<RegularPrice>;
    /** Total packages */
    totalPackages: Scalars['Int'];
    /** The item type */
    type: ItemType;
    /** The total weight. */
    weight: Weight;
};

/** The retail list, i.e online version of the list. */
export type RetailList = {
    __typename?: 'RetailList';
    /**
     * The currency code for pricing. Based on ISO 4217, which represents currency as a
     * three-letter alphabetic code ("EUR", "SEK").
     */
    currency: Maybe<Scalars['String']>;
    /** The total family price. */
    familyTotalPrice: Maybe<FamilyTotalPrice>;
    /** A list of all items in retail list */
    items: Array<RetailItem>;
    /** The total regular price. */
    regularTotalPrice: Maybe<RegularTotalPrice>;
    /** Total packages. */
    totalPackages: Maybe<Scalars['Int']>;
    /** The total weight. */
    weight: Maybe<Weight>;
};

/** The savings. */
export type Savings = {
    __typename?: 'Savings';
    /** The savings amount. */
    amount: Scalars['Float'];
};

/** The store contains information about the store. */
export type Store = {
    __typename?: 'Store';
    /** The store address. */
    address: StoreAddress;
    /** The name. */
    name: Scalars['String'];
    /** The current position based on GPS location. */
    position: Position;
    /** The store id. */
    storeId: Scalars['String'];
    /** The time zone id based on the area and location, e.g Europe/London. */
    timeZone: Scalars['String'];
};

/** The store address. */
export type StoreAddress = {
    __typename?: 'StoreAddress';
    /** The address. */
    address: Scalars['String'];
    /** The city. */
    city: Scalars['String'];
    /** The zip code. */
    zipCode: Scalars['String'];
};

/** The store article. */
export type StoreArticle = BaseArticle & {
    __typename?: 'StoreArticle';
    /** Is item collected? */
    isCollected: Scalars['Boolean'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** Storage location. */
    location: Maybe<Location>;
    /** Packages. */
    packages: Array<Package>;
    /** Product information. */
    product: Maybe<Product>;
    /** The quantityPerItem of an item. */
    quantityPerItem: Scalars['Int'];
    /** Weight per article. */
    weight: Weight;
};

/** StoreArticle sorting options */
export enum StoreArticleOrderOption {
    /** Sort articles by itemNo */
    Itemno = 'ITEMNO',
    /**
     * Sorts by location, moving articles with department to the front
     * of the list and then two stage sort on bin and aisle.
     */
    Location = 'LOCATION',
}

/** The Store item. */
export type StoreItem = BaseItem & {
    __typename?: 'StoreItem';
    /** UNIX time in seconds for when the item was added to the list. */
    addedAt: Scalars['Int'];
    /** Articles. */
    articles: Array<StoreArticle>;
    /** The family price. */
    familyPrice: Maybe<FamilyPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a breath taking item? */
    isBreathTakingItem: Scalars['Boolean'];
    /** Is this item communicated online? */
    isCommunicatedOnline: Scalars['Boolean'];
    /** Is the item a family item? */
    isFamilyItem: Scalars['Boolean'];
    /** Is the item a new item? */
    isNew: Scalars['Boolean'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** Product information. */
    product: Maybe<Product>;
    /** The quantity of an item. */
    quantity: Scalars['Int'];
    /** The regular price. */
    regularPrice: Maybe<RegularPrice>;
    /** Restocks data, a.k.a estimated back in stock. */
    restocks: Array<Restock>;
    /** Stock information. */
    stock: Maybe<Scalars['Int']>;
    /** Total packages */
    totalPackages: Scalars['Int'];
    /** The item type */
    type: ItemType;
    /** The total weight. */
    weight: Weight;
};

/** The Store item. */
export type StoreItemArticlesArgs = {
    orderBy: InputMaybe<StoreArticleOrderOption>;
};

/** The store list, i.e a specific store view of the list. */
export type StoreList = {
    __typename?: 'StoreList';
    /**
     * The currency code for pricing. Based on ISO 4217, which represents currency as a
     * three-letter alphabetic code ("EUR", "SEK").
     */
    currency: Maybe<Scalars['String']>;
    /** The family total price. */
    familyTotalPrice: Maybe<FamilyTotalPrice>;
    /** A list of all items in store list */
    items: Array<StoreItem>;
    /** The regular total price. */
    regularTotalPrice: Maybe<RegularTotalPrice>;
    /** The store id, e.g 012 for Stockholm Kungens kurva IKEA store */
    storeId: Scalars['String'];
    /** Total packages. */
    totalPackages: Maybe<Scalars['Int']>;
    /** The total weight. */
    weight: Maybe<Weight>;
};

/** The tax details. */
export type TaxDetail = {
    __typename?: 'TaxDetail';
    /** The tax amount. */
    amount: Scalars['Float'];
    /** The tax rate. */
    rate: Scalars['Float'];
    /** The taxable amount. */
    taxableAmount: Scalars['Float'];
    /** The tax type. */
    type: Scalars['String'];
};

/** The technical details. */
export type TechnicalDetails = {
    __typename?: 'TechnicalDetails';
    /**
     * Image PDF url
     * @deprecated This information will be included in media
     */
    imagePdfURL: Scalars['String'];
    /**
     * Image url
     * @deprecated This information will be included in media
     */
    imageUrl: Scalars['String'];
    /**
     * Label PDF url
     * @deprecated This information will be included in media
     */
    labelPdfUrl: Scalars['String'];
    /**
     * Label url
     * @deprecated This information will be included in media
     */
    labelUrl: Scalars['String'];
};

/** Total weight type. */
export type TotalWeight = {
    __typename?: 'TotalWeight';
    /** The imperial weight. */
    imperial: Scalars['String'];
    /** The metric weight. */
    metric: Scalars['String'];
};

/** The unit type. */
export enum UnitType {
    /** The area type. */
    Area = 'AREA',
    /** The meterware type. */
    Meterware = 'METERWARE',
    /** The multipack type. */
    Multipack = 'MULTIPACK',
    /** The piece type. */
    Piece = 'PIECE',
    /** The volume type. */
    Volume = 'VOLUME',
    /** The weight type. */
    Weight = 'WEIGHT',
    /** The weightware type. */
    Weightware = 'WEIGHTWARE',
}

/** The necessary input when updating items. */
export type UpdateItemInput = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The quantity of items. */
    quantity: Scalars['Int'];
};

/** Weight type. */
export type Weight = {
    __typename?: 'Weight';
    /** The imperial weight. */
    imperial: Maybe<Imperial>;
    /** The metric weight. */
    metric: Maybe<Metric>;
};

/** The weight per item type. */
export type WeightPerArticle = {
    __typename?: 'WeightPerArticle';
    /** The imperial weight. */
    imperial: Scalars['String'];
    /** The metric weight. */
    metric: Scalars['String'];
};

export type InspirationFragment = {
    __typename?: 'Inspiration';
    id: string;
    shortId: string;
    url: string;
    slug: string;
};

export type AddInspirationsMutationVariables = Exact<{
    ids: Array<Scalars['ID']> | Scalars['ID'];
    languageCode: Scalars['String'];
}>;

export type AddInspirationsMutation = {
    __typename?: 'Mutation';
    inspirations: Array<{
        __typename?: 'Inspiration';
        id: string;
        shortId: string;
        url: string;
        slug: string;
    }>;
};

export type MoveInspirationsMutationVariables = Exact<{
    sourceUserId: Scalars['ID'];
    languageCode: Scalars['String'];
}>;

export type MoveInspirationsMutation = {
    __typename?: 'Mutation';
    inspirations: Array<{
        __typename?: 'Inspiration';
        id: string;
        shortId: string;
        url: string;
        slug: string;
    }>;
};

export type RemoveInspirationsMutationVariables = Exact<{
    ids: Array<Scalars['ID']> | Scalars['ID'];
    languageCode: Scalars['String'];
}>;

export type RemoveInspirationsMutation = {
    __typename?: 'Mutation';
    inspirations: Array<{
        __typename?: 'Inspiration';
        id: string;
        shortId: string;
        url: string;
        slug: string;
    }>;
};

export type GetInspirationsQueryVariables = Exact<{
    languageCode: Scalars['String'];
}>;

export type GetInspirationsQuery = {
    __typename?: 'Query';
    inspirations: Array<{
        __typename?: 'Inspiration';
        id: string;
        shortId: string;
        url: string;
        slug: string;
    }>;
};

export const InspirationFragmentDoc = `
    fragment Inspiration on Inspiration {
  id
  shortId
  url
  slug
}
    `;
export const AddInspirationsDocument = `
    mutation AddInspirations($ids: [ID!]!, $languageCode: String!) {
  inspirations: addInspirations(ids: $ids, languageCode: $languageCode) {
    ...Inspiration
  }
}
    ${InspirationFragmentDoc}`;
export const useAddInspirationsMutation = <
    TError = unknown,
    TContext = unknown
>(
    options?: UseMutationOptions<
        AddInspirationsMutation,
        TError,
        AddInspirationsMutationVariables,
        TContext
    >
) =>
    useMutation<
        AddInspirationsMutation,
        TError,
        AddInspirationsMutationVariables,
        TContext
    >(
        ['AddInspirations'],
        useFetchData<AddInspirationsMutation, AddInspirationsMutationVariables>(
            AddInspirationsDocument
        ),
        options
    );
export const MoveInspirationsDocument = `
    mutation MoveInspirations($sourceUserId: ID!, $languageCode: String!) {
  inspirations: moveInspirations(
    sourceUserId: $sourceUserId
    languageCode: $languageCode
  ) {
    ...Inspiration
  }
}
    ${InspirationFragmentDoc}`;
export const useMoveInspirationsMutation = <
    TError = unknown,
    TContext = unknown
>(
    options?: UseMutationOptions<
        MoveInspirationsMutation,
        TError,
        MoveInspirationsMutationVariables,
        TContext
    >
) =>
    useMutation<
        MoveInspirationsMutation,
        TError,
        MoveInspirationsMutationVariables,
        TContext
    >(
        ['MoveInspirations'],
        useFetchData<
            MoveInspirationsMutation,
            MoveInspirationsMutationVariables
        >(MoveInspirationsDocument),
        options
    );
export const RemoveInspirationsDocument = `
    mutation RemoveInspirations($ids: [ID!]!, $languageCode: String!) {
  inspirations: removeInspirations(ids: $ids, languageCode: $languageCode) {
    ...Inspiration
  }
}
    ${InspirationFragmentDoc}`;
export const useRemoveInspirationsMutation = <
    TError = unknown,
    TContext = unknown
>(
    options?: UseMutationOptions<
        RemoveInspirationsMutation,
        TError,
        RemoveInspirationsMutationVariables,
        TContext
    >
) =>
    useMutation<
        RemoveInspirationsMutation,
        TError,
        RemoveInspirationsMutationVariables,
        TContext
    >(
        ['RemoveInspirations'],
        useFetchData<
            RemoveInspirationsMutation,
            RemoveInspirationsMutationVariables
        >(RemoveInspirationsDocument),
        options
    );
export const GetInspirationsDocument = `
    query GetInspirations($languageCode: String!) {
  inspirations(languageCode: $languageCode) {
    ...Inspiration
  }
}
    ${InspirationFragmentDoc}`;
export const useGetInspirationsQuery = <
    TData = GetInspirationsQuery,
    TError = unknown
>(
    variables: GetInspirationsQueryVariables,
    options?: UseQueryOptions<GetInspirationsQuery, TError, TData>
) =>
    useQuery<GetInspirationsQuery, TError, TData>(
        ['GetInspirations', variables],
        useFetchData<GetInspirationsQuery, GetInspirationsQueryVariables>(
            GetInspirationsDocument
        ).bind(null, variables),
        options
    );
