import { SettingsObject } from '.';
import {
    auen,
    caen,
    cafr,
    czcs,
    czen,
    envDefaults,
    fifi,
    gben,
    hrhr,
    huhu,
    ieen,
    inen,
    kren,
    krko,
    plpl,
    pten,
    ptpt,
    usen,
} from '../../environments';

const markets = {
    auen,
    caen,
    cafr,
    czcs,
    czen,
    fifi,
    gben,
    hrhr,
    huhu,
    ieen,
    inen,
    kren,
    krko,
    plpl,
    pten,
    ptpt,
    usen,
} as Record<string, Partial<SettingsObject>>;

export const getFiles = (market: string) => {
    if (market in markets) {
        return {
            ...envDefaults,
            ...markets[market],
        } as SettingsObject;
    }
    return envDefaults as SettingsObject;
};
