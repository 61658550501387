import { handleInspirationRemovedError } from './inspiration/handlers';
import { GetInspirationsQuery } from './inspiration/reactQueryHooks';
import { CommonVariables, OmitCommon, ServerResponse } from './types';
import useLocale from '@hooks/useLocale';

export type Inspirations = GetInspirationsQuery['inspirations'];

const handleErrorData = <T>(
    response: ServerResponse<T & { inspirations: Inspirations }>
) => {
    if (response && response.errors) {
        response.errors.forEach(error => {
            if (error.extensions?.code === 'UNAVAILABLE_INSPIRATIONS_REMOVED') {
                handleInspirationRemovedError(error.extensions);
            }
            if (error.extensions?.code === 'INSPIRATIONS_NOT_FOUND') {
                console.warn('Inspiration not found');
            }
        });
    }
    return response;
};

const useMutationHook = <T>(
    mutation: any,
    handleSuccess?: (data: any, commonVariables: CommonVariables) => void,
    handleError?: (error: any) => void,
    callback?: () => void
) => {
    const { language } = useLocale();
    const { mutateAsync, isLoading } = mutation({
        onSuccess: (data: any) =>
            handleSuccess
                ? handleSuccess(data, { languageCode: language })
                : null,
        onError: (error: any) => (handleError ? handleError(error) : null),
    });

    const mutationHandler = async (variables: OmitCommon<T>) => {
        const response = await mutateAsync({
            languageCode: language,
            ...variables,
        });
        if (callback) callback();
        handleErrorData(response);
    };

    return { mutationHandler, isLoading };
};

export default useMutationHook;
