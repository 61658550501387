import { GetInspirationsQuery } from './reactQueryHooks';
import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { publish } from '@utils/window/pubsub';

import {
    CommonVariables,
    UnavailableInspirationsRemovedError,
} from 'apis/types';

export const handleSuccess =
    (queryClient: QueryClient) =>
    (data: GetInspirationsQuery, commonVariables: CommonVariables) => {
        queryClient.setQueryData(
            ['GetInspirations', commonVariables],
            (old: any) => ({
                inspirations: data ? data.inspirations : old?.inspirations,
            })
        );
    };

export const handleError = (error: any) => captureException(error);

export const handleInspirationRemovedError = (
    inspirationsRemovedError: UnavailableInspirationsRemovedError
) => {
    publish(
        'UNAVAILABLE_INSPIRATIONS_REMOVED',
        inspirationsRemovedError.data?.removed_inspirations?.length ?? 1
    );
};
