import { Auth } from './useAuth2';
import useLocale from './useLocale';

import { getCookie, setCookie } from 'utils/cookie';

const useSetDevCookies = () => {
    const { country, language } = useLocale();
    const setDevCookies = (tokens: Auth['tokens']) => {
        if (tokens.guest?.value && !getCookie('guest')) {
            console.log('detected missing cookie! setting guest');
            setCookie('guest', country, language, tokens.guest.value, 30);
        }
        if (tokens.auth?.value && !getCookie('idp_reguser')) {
            console.log('detected missing cookie! setting idp_reguser');
            setCookie(
                'idp_reguser',
                country,
                language,
                tokens.auth.value,
                30,
                true
            );
        }
    };
    return setDevCookies;
};

export default useSetDevCookies;
