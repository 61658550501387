import React, { ReactNode } from 'react';

import useMoveFromGuestToLoggedInUser from '@hooks/useMoveFromGuestToLoggedInUser';

interface PageContainerProps {
    children: ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => {
    useMoveFromGuestToLoggedInUser();
    return (
        <main className="list-ingka-page-container">
            <div className="list-ingka-page-container__inner">
                <div className="list-ingka-page-container__main">
                    {children}
                </div>
            </div>
        </main>
    );
};

export default PageContainer;
