import { useMemo } from 'react';

import useLocale from '../useLocale';
import { getMarketSettings } from '@hooks/useSettings/getMarketSettings';

export type SettingsObject = {
    baseUrl: string;
    browseProductsUrl: string;
    cartClientId: string;
    cartEndpoint: string;
    cartUrl: string;
    ecoFeeParticipationUrl: string;
    enableAlternativeAvailabilityCheck: boolean;
    esiBase: string;
    familySignupUrl: string;
    familyUpgradeUrl: string;
    giftRegistryClientId: string;
    giftRegistryEndpoint: string;
    giftRegistryManageUrl: string;
    hideAddToCart: boolean;
    hideRetailMode: boolean;
    hideStoreMode: boolean;
    includeVAT: boolean;
    inspirationLink: string;
    listClientId: string;
    listEndpoint: string;
    loginUrl: string;
    maxItems: number;
    maxListNameLength: number;
    maxLists: number;
    measurement: 'metric' | 'imperial' | 'both';
    optimizelyBase: string;
    optimizelyKey: string;
    pipUrl: string;
    profileClientId: string;
    profileEndpoint: string;
    publicUrl: string;
    sentryDsn: string;
    shareUrl: string;
    showDevModal: boolean;
    showDualCurrency: boolean;
    showEcoFee: boolean;
    showInspirations: boolean;
    showVAT: boolean;
    signupUrl: string;
    sunClientId: string;
    sunEnable: boolean;
    sunEndpoint: string;
    sunPrivacyPolicyLink: string;
    urlBaseName: string;
    websiteBase: string;
};

export const useSettings = (): SettingsObject => {
    const localeData = useLocale();
    return useMemo(
        () => getMarketSettings(localeData.ikeaMarketName),
        [localeData.ikeaMarketName]
    );
};
