export const mockConsent = () => {
    if (typeof window === 'undefined') {
        return;
    }
    if (!window.ikea) {
        // @ts-ignore
        window.ikea = {};
    }
    window.ikea.cookieConsent = {
        interactedWithBanner: true,
        hasConsent: () => true,
    };
};
