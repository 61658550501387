// All defaults
// Market defaults
import cteDefaults from './cte-defaults.json';
import defaults from './defaults.json';
import localhostDefaults from './localhost-defaults.json';
import ppeDefaults from './ppe-defaults.json';
import prCteDefaults from './pr-cte-defaults.json';
import prPpeDefaults from './pr-ppe-defaults.json';

const defaultsByEnv: Record<string, Object> = {
    ppe: ppeDefaults,
    cte: cteDefaults,
    'pr-ppe': prPpeDefaults,
    'pr-cte': prCteDefaults,
    localhost: localhostDefaults,
};

export const envDefaults = {
    ...defaults,
    ...defaultsByEnv[process.env.NEXT_PUBLIC_IKEA_ENV ?? 'localhost'],
};
