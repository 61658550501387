import { useCallback, useMemo, useState } from 'react';

import useLocale from './useLocale';

type Setter<T> = (oldValue: T) => T;

export default function useLocalStorage<T>(
    key: string,
    initialValue: T
): [T, (value: T | Setter<T>) => void] {
    const { country, language } = useLocale();
    const prefix = `${country}/${language}/list/`;
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            // console.warn('detected server, skipping useLocalStorage');
            return;
        }
        try {
            const item =
                window?.localStorage &&
                window?.localStorage.getItem(prefix + key);
            return item != null ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error('failed to read from localstorage', error);
            return initialValue;
        }
    });

    const setValue = useCallback(
        (value: T | Setter<T>) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                // Save to local storage
                window?.localStorage &&
                    window.localStorage.setItem(
                        prefix + key,
                        JSON.stringify(valueToStore)
                    );
            } catch (error) {
                console.error('failed to save to localStorage', error);
            }
        },
        [key, prefix, storedValue]
    );

    return useMemo(() => [storedValue, setValue], [storedValue, setValue]);
}
