import React, { useEffect } from 'react';

import useTranslations from '@hooks/useTranslations';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { subscribe, unsubscribe } from 'utils/window/pubsub';

import useMonitoring from 'hooks/useMonitoring';

const events = {
    COOKIE_CONSENT_CHANGED: 'ikeaCookieConsent/changed',
};

const ComponentWrapper = ({
    Component,
    pageProps,
    router,
    err,
}: AppProps & { err: unknown }) => {
    const { translate } = useTranslations();
    const { initSentry } = useMonitoring();

    useEffect(() => {
        subscribe(events.COOKIE_CONSENT_CHANGED, () => initSentry());

        return () => {
            unsubscribe(events.COOKIE_CONSENT_CHANGED, () => initSentry());
        };
    }, []);

    return (
        <>
            <Head>
                <title>{translate('Webpage title')}</title>
            </Head>
            <Component {...pageProps} err={err} router={router} />
        </>
    );
};

export default ComponentWrapper;
