import { SettingsObject } from '.';
import { MarketPresets } from './getMarketSettings';

export const expandSettingsValues = (
    settings: SettingsObject,
    marketPresets: MarketPresets
): SettingsObject => {
    return Object.keys(settings).reduce(
        (prev: SettingsObject, curr: string): SettingsObject => {
            /* This will return an array of keys from a string.
               Example:
                 getInlineKeys('All available items were moved from {sourceListName} to {destinationListName}')
                 returns: ['sourceListName', 'destinationListName']
            */
            const getInlineKeys = (settingsValue: string) => {
                let start = -1;
                const keysToReplace = [];
                for (let i = 1; i < settingsValue.length; i++) {
                    const c = settingsValue[i];
                    const prev = settingsValue[i - 1];
                    if (prev === '$' && c === '{') {
                        start = i + 1;
                    }
                    if (start && c === '}') {
                        const end = i;
                        const key = settingsValue.substring(start, end);
                        keysToReplace.push(key);
                        start = -1;
                    }
                }
                return keysToReplace;
            };
            const extrapolateKeysReducer = (
                settingsValue: string,
                key: string
            ) => {
                const fullkey = '${' + key + '}';
                /**
                 * Magic key 'from'
                 *
                 * If this occurs we need to replace it with
                 * our website URL, but URIEncoded.
                 * Because this information has not yet been
                 * expanded, we generate it manually:
                 */
                if (key === 'from') {
                    const { baseUrl, urlBaseName } = settings;
                    const { country, language } = marketPresets;
                    const url = `${baseUrl}/${country}/${language}/${urlBaseName}/`;
                    return settingsValue.replace(
                        fullkey,
                        encodeURIComponent(url)
                    );
                }
                // @ts-ignore
                const valueToReplace = settings[key] ?? marketPresets[key];
                return settingsValue.replace(fullkey, valueToReplace);
            };
            // @ts-ignore
            const val = settings[curr];
            const keysToReplace = getInlineKeys(val);
            // Extrapolate all keys in the string
            const extrapolatedVal = keysToReplace.reduce(
                extrapolateKeysReducer,
                val
            );
            return {
                ...prev,
                [curr]: extrapolatedVal,
            };
        },
        {} as SettingsObject
    );
};
