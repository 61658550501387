import { expandSettingsValues } from './expandSettingsValues';
import { getFiles } from './getFiles';
import { getLocaleFromPath } from '@utils/getLocaleFromPath';

export type MarketPresets = {
    country: string;
    language: string;
    locale: string;
};

export const getMarketSettings = (market: string) => {
    const { country, language, locale } = getLocaleFromPath(market);
    const marketPresets: MarketPresets = {
        country,
        language,
        locale,
    };

    const filesSettings = getFiles(market);
    return expandSettingsValues(filesSettings, marketPresets);
};
