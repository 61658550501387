/* Heads up! Docs are in ./analytics.md */
import { version } from 'utils/settings';

export const LIST_TYPE = 'onlineshoppinglist';

interface GoogleAnalyticsEventObj {
    name?: string;
    version?: string;
    event_category?: string;
    page_path?: string;
    page_location?: string;
    custom?: {
        [key: string]: any;
    };
    params?: {
        [key: string]: any;
    };
}

type CheckoutActions = {
    category: 'checkout_actions';
    action:
        | 'increase_amount'
        | 'decrease_amount'
        | 'calculate_delivery'
        | 'taskrabbit_postal_code'
        | 'assembly_available'
        | 'assembly_added'
        | 'selected_date'
        | 'created_cart'
        | 'delivery_map'
        | 'cta_button_click'
        | 'wishlist'; // This is missing
    label?: string;
    custom?: {};
};
type OptimizelyActions = {
    category: 'optimizely';
    action: 'user bucketed';
    label: string;
    custom?: {};
};
type FavouritesActions = {
    category: 'favourites';
    action:
        | 'add_to_cart'
        | 'add_to_registry'
        | 'create_list'
        | 'login'
        | 'view_received_list'
        | 'favourites_landing_page_no_lists'
        | 'subscribe_stock_notification'
        | 'promote_family_click';

    label?: string;
    custom?: {};
};
type InspirationActions = {
    category: 'inspiration';
    action:
        | 'removed_from_inspiration'
        | 'favourites_image_click'
        | 'favorited_images'
        | 'get_inspired_from_favourites_click';
    label?: string;
    custom?: {};
};
type EcommerceActions = {
    category: 'ecommerce';
    action:
        | 'add_to_wishlist'
        | 'remove_from_wishlist'
        | 'print_wishlist'
        | 'email_wishlist'
        | 'save_wishlist'
        | 'cross_off_checklist'
        | 'add_samuraj'
        | 'created_cart'
        | 'view_list'
        | 'create_list'
        | 'rename_list'
        | 'remove_list'
        | 'wishlist_store_mode'
        | 'wishlist_online_mode'
        | 'wishlist_increase_amount'
        | 'wishlist_decrease_amount';

    label?: string;
    custom?: {};
};
type ShoppingCartActions = {
    name: 'add_to_cart';
    label?: string;
    custom?: {};
    params?: {};
};
declare const window: Window & {
    sendEvent?: (eventObj: GoogleAnalyticsEventObj) => void;
};

const defaultOptions = {
    version: 'List ' + String(version),
    site_market: 'TODO country', //TODO
    site_language: 'TODO language', //TODO
    page_type: LIST_TYPE,
};
export const logEvent = ({
    category,
    action,
    label = 'favourites',
    custom = {},
}:
    | CheckoutActions
    | EcommerceActions
    | FavouritesActions
    | InspirationActions
    | OptimizelyActions) => {
    const options = {
        ...defaultOptions,
        ...(action && {
            event_action: action,
        }),
        ...(category && {
            event_category: category,
        }),
        ...(label && {
            event_label: label,
        }),
        ...(Object.keys(custom).length !== 0 && { custom }),
    };
    if (
        typeof window !== 'undefined' &&
        typeof window.sendEvent === 'function'
    ) {
        window.sendEvent(options);
    }
};

export const logEcommerceEvent = ({
    label,
    name,
    params,
    custom,
}: ShoppingCartActions) => {
    if (
        typeof window !== 'undefined' &&
        typeof window.sendEvent === 'function'
    ) {
        const options = {
            name,
            ...(params &&
                Object.keys(params).length > 0 && {
                    params,
                }),
            ...(label && {
                event_label: label,
            }),
            custom: {
                ...defaultOptions,
                ...custom,
            },
        };
        typeof window !== 'undefined' && window.sendEvent(options);
    }
};

// Analytics wants a product identifier in the form "product_name product_id"
// for example "ARKELSTORP 30260807"
export const productId = (name: string, itemNo: string) => name + ' ' + itemNo;
