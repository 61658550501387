import axios from 'axios';

import useAuth from 'hooks/useAuth2';
import { useSettings } from 'hooks/useSettings';

/**
 * This is a fetcher function which we provide for the generated
 * graphql hooks in reactQueryHooks.ts.
 */
export function useFetchData<TData, TVariables>(
    query: string
): (variables?: TVariables) => Promise<TData> {
    const { accessToken } = useAuth();
    const { listEndpoint, listClientId } = useSettings();

    return async (variables?: TVariables) => {
        const res = await axios.post(
            listEndpoint,
            JSON.stringify({ query, variables }),
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'X-Client-Id': listClientId,
                },
            }
        );

        const json = await res.data;

        try {
            if (json.errors) {
                const { message } = json.errors[0];
                throw new Error(message);
            }
        } catch (error) {
            console.error(error);
        }

        return json.data;
    };
}
