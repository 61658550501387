import { useCallback, useMemo } from 'react';

import * as Sentry from '@sentry/react';
import { useSettings } from './useSettings';

import useMoveInspirations from 'apis/inspiration/hooks/useMoveInspirations';
import { useMoveLists } from 'apis/list/operations';

import getUserIdFromToken from 'utils/get-userid-from-token';

type Token = {
    value: string;
    expirationTime: number;
};

const useMoveUser = () => {
    const { showInspirations } = useSettings();
    const { moveInspirations } = useMoveInspirations();
    const { moveLists } = useMoveLists();

    const moveInspirationsToLoggedInUser = useCallback(
        (sourceUserId: string): Promise<void> => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async resolve => {
                try {
                    await moveInspirations({ sourceUserId });
                } catch (e) {
                    Sentry.captureException(
                        new Error(
                            `Error when trying to move inspirations to logged in user. ${e}`
                        )
                    );
                } finally {
                    resolve(); // Always resolve, since failures when Moving Inspirations are noncritical
                }
            });
        },
        [moveInspirations]
    );

    const moveListsToLoggedInUser = useCallback(
        (sourceUserId: string): Promise<void> => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                try {
                    await moveLists({ sourceUserId });
                    resolve();
                } catch (e) {
                    Sentry.captureException(
                        new Error(
                            `Error when trying to move lists to logged in user. ${e}`
                        )
                    );
                    reject();
                }
            });
        },
        [moveLists]
    );

    // Only run this if the conditions apply!
    const moveFromGuestToLoggedInUser = useCallback(
        async (guestToken: Token) => {
            const sourceUserId = getUserIdFromToken(guestToken.value);
            if (showInspirations) {
                await moveInspirationsToLoggedInUser(sourceUserId);
            }
            await moveListsToLoggedInUser(sourceUserId);
        },
        [
            moveInspirationsToLoggedInUser,
            moveListsToLoggedInUser,
            showInspirations,
        ]
    );

    const useMoveUser = useMemo(
        () => ({ moveFromGuestToLoggedInUser }),
        [moveFromGuestToLoggedInUser]
    );
    return useMoveUser;
};

export default useMoveUser;
