import markets from '../../markets.json';

type IkeaMarketName = typeof markets[number]; // I was hoping it would induce the keys here...

export type MarketData = {
    appPath: string;
    country: string;
    ikeaMarketName: IkeaMarketName;
    language: string;
    locale: string;
    prefix: 'favorites' | 'favourites';
};

export const getMarketData = (ikeaMarketName: IkeaMarketName): MarketData => {
    if (!markets.includes(ikeaMarketName)) {
        throw Error(`This market doesn´t exist: '${ikeaMarketName}'`);
    }
    const country = ikeaMarketName[0] + ikeaMarketName[1];
    const language = ikeaMarketName[2] + ikeaMarketName[3];
    const locale =
        country === 'no' ? 'nb-NO' : `${language}-${country.toUpperCase()}`;
    const prefix = 'favourites';
    return {
        appPath: `/${country}/${language}/${prefix}`,
        country,
        ikeaMarketName, // for good measure
        language,
        locale,
        prefix,
    };
};

const marketPrefixes = markets.reduce((prev, ikeaMarketName) => {
    prev[ikeaMarketName] = getMarketData(ikeaMarketName);
    return prev;
}, {} as Record<IkeaMarketName, MarketData>);

export const getLocaleFromPath = (requestPath: string) => {
    const defaultLocale = marketPrefixes['gben'];
    if (requestPath.startsWith('/_next/')) {
        return defaultLocale;
    }
    // I'm actually wondering when this first case applies:
    const countryLanguageWithoutSlash = /([a-z]{2})([a-z]{2})/; // 'ccll'
    const countryLanguageWithSlash = /([a-z]{2})\/([a-z]{2})/; // 'cc/ll'
    const regex =
        requestPath.length === 4
            ? countryLanguageWithoutSlash
            : countryLanguageWithSlash;
    const match = regex.exec(requestPath);
    if (!match) {
        console.warn(`Can't detect locale from path, defaulting`);
        return defaultLocale;
    }
    const ikeaMarketName = `${match[1]}${match[2]}`;
    if (!marketPrefixes[ikeaMarketName]) {
        console.warn(
            `Market ${ikeaMarketName} is not allowed, defaulting. \n  - Path was ${requestPath}`
        );
        return defaultLocale;
    }
    return marketPrefixes[ikeaMarketName];
};
