import { useEffect } from 'react';

import useAuth from './useAuth2';
import useLocale from './useLocale';
import useMoveUser from './useMoveUser';

import { getCookie, setCookie } from 'utils/cookie';

type Token = {
    value: string;
    expirationTime: number;
};

const useMoveFromGuestToLoggedInUser = () => {
    const { isLoggedIn, tokens } = useAuth();
    const { country, language } = useLocale();
    const { moveFromGuestToLoggedInUser } = useMoveUser();
    const checkAreTokensOutOfSync = async () => {
        if (isLoggedIn && tokens && tokens.guest && tokens.auth) {
            const authToken = tokens.auth as Token;
            const currentAccessTokenExpiry =
                authToken.expirationTime.toString();
            const savedAccessTokenExpiry = getCookie(
                'favouritesStoredAccessTokenExpiry'
            );

            const areTokensOutOfSync =
                currentAccessTokenExpiry !== savedAccessTokenExpiry;
            if (areTokensOutOfSync) {
                try {
                    await moveFromGuestToLoggedInUser(tokens.guest as Token);
                    setCookie(
                        'favouritesStoredAccessTokenExpiry',
                        country,
                        language,
                        currentAccessTokenExpiry
                    );
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    useEffect(() => {
        checkAreTokensOutOfSync();
    }, [isLoggedIn, tokens]);
};

export default useMoveFromGuestToLoggedInUser;
