/**
 * Note
 *
 * The types that are below have been transcribed from the following source of information:
 * https://confluence.build.ingka.ikea.com/pages/viewpage.action?spaceKey=OW&title=Guest+Token+Service
 * However some of the facts on that page are no longer true.
 * Even though we won't need TokenHeader at the time, it could be useful to keep the type for the future.
 */

// Chunk 0 is the base64-encoded TokenHeader
type TokenHeader = {
    alg: string; // Algorithm ie. "RS256"
    typ: string; // Type ie. "JWT"
    kid: string; // Key ID
};

// Chunk 1 is the base64-encoded Payload
export type TokenPayload = {
    exp: number; // Expiry date (seconds since epoch)
    iat: number; // (Issued at) - Time (seconds since epoch) when the token was issued
    iss: string; // iss(Issuer) - <domain url of guest service>
    retailUnit: string; // Market, ie. "se"
    sub?: string; // Guestid, UUID(v4)
    PartyUID?: string; // Logged in ID
};

export type UnpackedToken = {
    header: TokenHeader;
    payload: TokenPayload;
    signature: string;
};

// Chunk 3 is a string: RSASHA256( base64UrlEncode(header) + "." + base64UrlEncode(payload), publickey, privatekey)

export default (token: string): UnpackedToken => {
    const [b64header, b64payload, signature] = token.split('.');
    const header: TokenHeader = JSON.parse(atob(b64header));
    const payload: TokenPayload = JSON.parse(atob(b64payload));
    return {
        header,
        payload,
        signature,
    };
};
