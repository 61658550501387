import { useContext, useMemo } from 'react';

import { OptimizelyContext } from './OptimizelyWrapper';

export const useOptimizely = () => {
    const getWrapper = useContext(OptimizelyContext);
    return useMemo(
        () => ({
            activateExperiments: () => {},
            getExperimentVariation: (
                name: string,
                variations: { [key: string]: string }
            ) => {
                const ctx = getWrapper();
                if (ctx) {
                    return ctx.activate(name);
                }
                return Object.values(variations)[0];
            },
            trackEvent: (eventName: string) => getWrapper()?.track(eventName),
            decide: (decisionKey: string) => {
                const decision = getWrapper()?.decide(decisionKey);
                return decision;
            },
        }),
        [getWrapper]
    );
};
